import React from 'react'

import ContactForm from '../components/ContactForm'

const Contactpage = () => {
  return (
    <div>
      <ContactForm/>
    </div>
  )
}

export default Contactpage
