import React from 'react'

const Automationpage = () => {
  return (
    <div>

      
    </div>
  )
}

export default Automationpage
