import React from 'react'

const CustomSolutionspage = () => {
  return (
    <div>
      
    </div>
  )
}

export default CustomSolutionspage
